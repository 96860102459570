// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_07-module--afterCaption--44fdd";
export var colWrapper = "maine_digest_2022_07-module--colWrapper--37137";
export var columnWrapper = "maine_digest_2022_07-module--columnWrapper--40427";
export var dateline = "maine_digest_2022_07-module--dateline--5d4f7";
export var heading = "maine_digest_2022_07-module--heading--67119";
export var headingLogo = "maine_digest_2022_07-module--headingLogo--b4698";
export var headingWrapper = "maine_digest_2022_07-module--headingWrapper--1344d";
export var heroImage = "maine_digest_2022_07-module--heroImage--872a8";
export var instance = "maine_digest_2022_07-module--instance--0b7ec";
export var latestnewsarticleheadline = "maine_digest_2022_07-module--latestnewsarticleheadline--cfc5d";
export var leftCol = "maine_digest_2022_07-module--leftCol--6bc53";
export var maineDigest = "maine_digest_2022_07-module--maineDigest--d2744";
export var photoCaption = "maine_digest_2022_07-module--photoCaption--86828";
export var rightCol = "maine_digest_2022_07-module--rightCol--7d49f";
export var subheading = "maine_digest_2022_07-module--subheading--3d4b8";
export var textWrapper = "maine_digest_2022_07-module--textWrapper--58c49";
export var whatsNew = "maine_digest_2022_07-module--whatsNew--873fe";